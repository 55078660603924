<template>
  <div>
    <Row :gutter="10">
      <Button size="large" type="primary" @click="modal_createtask = true">
        <Icon type="md-add-circle" />
        添加交换机
      </Button>
      <Modal
        v-model="modal_createtask"
        footer-hide
        title="添加交换机"
        @on-visible-change="createSwitchReset"
      >
        <!-- <Form :rules="ruleInline"> -->
        <Form
          ref="createswitch"
          :model="switch_create"
          :rules="ruleInline2"
          :label-width="80"
        >
          <FormItem prop="name" label="交换机名称" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="switch_create.name"
              placeholder="Name"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="community" label="SNMP账户" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="switch_create.community"
              placeholder="Community"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="ssh_username" label="SSH账户" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="switch_create.ssh_username"
              placeholder="ssh_username"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="ssh_password" label="SSH密码" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="switch_create.ssh_password"
              placeholder="ssh_password"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              @click="createswitch()"
              style="position: absolute; left: 20%;"
            >
              提交
            </Button>
            <Button
              @click="createSwitchReset()"
              style="position: absolute; left: 45%;"
            >
              重置
            </Button>
          </FormItem>
        </Form>
      </Modal>

      <Modal
        v-model="modal_createcollect"
        footer-hide
        title="添加监控"
        @on-visible-change="canelcreatecollect()"
      >
        <Form
          ref="createcollect"
          :model="collect_create"
          :rules="ruleInline3"
          :label-width="80"
        >
          <FormItem prop="collect_ip" label="交换机IP" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="collect_create.collect_ip"
              placeholder="collect_ip"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="netconf_port" label="NetConf端口" :label-width="100">
            <Input
              type="text"
              size="large"
              v-model="collect_create.netconf_port"
              placeholder="netconf_port"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="order_id" label="优先级" :label-width="100">
            <InputNumber
              :max="10"
              :min="0"
              v-model="collect_create.order_id"
              size="large"
              style="width: 300px;"
            ></InputNumber>
          </FormItem>

          <FormItem label="连接方式" required :label-width="100">
            <RadioGroup v-model="collect_create.collect_mode">
              <Radio label="公网直连"></Radio>
              <Radio label="服务器代理"></Radio>
            </RadioGroup>
          </FormItem>

          <FormItem
            v-if="collect_create.collect_mode == '服务器代理'"
            prop="deviceuuid"
            label="代理服务器ID"
            :label-width="100"
          >
            <Input
              type="text"
              size="large"
              v-model="collect_create.deviceuuid"
              placeholder="代理服务器ID"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              @click="createcollect()"
              style="position: absolute; left: 20%;"
            >
              提交
            </Button>
            <Button
              @click="createCollectReset()"
              style="position: absolute; left: 45%;"
            >
              重置
            </Button>
          </FormItem>
        </Form>
      </Modal>

      <Modal
        v-model="showcollectpage"
        title="采集列表"
        footer-hide
        width="1410px"
      >
        <Button size="large" type="primary" @click="modal_createcollect = true">
          <Icon type="md-add-circle" />
          添加监控
        </Button>
        <br />
        <br />

        <Table
          v-if="switch_collects.length > 0"
          border
          ref="aaa"
          :columns="collect_columns"
          :data="switch_collects"
        >
          <template slot-scope="{ row }" slot="collect_ip">
            <Input v-model="row.collect_ip" placeholder="large size" />
          </template>
        </Table>
      </Modal>

      <Modal v-model="showinterfacepage" title="端口列表" width="1400px">
        <Table
          v-if="switch_interface.length > 0"
          border
          ref="aaa"
          :columns="interface_columns"
          :data="switch_interface"
        >
          <template slot-scope="{ row }" slot="is_out">
            <i-switch v-model="switch_interface[row._index].is_out" />
          </template>

          <template slot-scope="{ row }" slot="out_limit">
            <InputNumber
              :max="1000000"
              :min="0"
              v-model="switch_interface[row._index].out_limit"
            ></InputNumber>
          </template>

          <template slot-scope="{ row }" slot="in_limit">
            <InputNumber
              :max="1000000"
              :min="0"
              v-model="switch_interface[row._index].in_limit"
            ></InputNumber>
          </template>
        </Table>

        <div slot="footer">
          <Button type="primary" size="large" long @click="updateinterface">
            更新
          </Button>
        </div>
      </Modal>

      <Button
        size="large"
        type="primary"
        style="width: 180px; position: absolute; right: 25%;"
        @click="exportSwitchInfo"
      >
        <Icon type="ios-cloud-download" />
        导出交换机信息
      </Button>

      <Input
        size="large"
        search
        enter-button
        placeholder="交换机名/SN"
        @on-search="searchdata"
        v-model="searchwithsntmp"
        style="width: 400px; position: absolute; right: 3%;"
      />

      <br />
      <Drawer
        title="交换机信息"
        :closable="false"
        @on-close="closedrawer()"
        v-model="modal_updatetask"
        width="35"
      >
        <!-- <Form :rules="ruleInline"> -->
        <Form
          ref="updateform"
          :model="switch_update"
          :rules="ruleInline"
          :label-width="80"
        >
          <FormItem prop="name" label="交换机名称" :label-width="200">
            <Input
              type="text"
              size="large"
              v-model="switch_update.name"
              placeholder="Name"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <FormItem prop="serial_number" label="交换机SN" :label-width="200">
            <Input
              type="text"
              size="large"
              disabled
              v-model="switch_update.serial_number"
              placeholder="Serial_number"
              style="width: 300px;"
            ></Input>
          </FormItem>
          <FormItem prop="community" label="SNMP账户" :label-width="200">
            <Input
              type="text"
              size="large"
              v-model="switch_update.community"
              placeholder="Community"
              style="width: 300px;"
            ></Input>
          </FormItem>
          <FormItem prop="ssh_username" label="SSH账户" :label-width="200">
            <Input
              type="text"
              size="large"
              v-model="switch_update.ssh_username"
              placeholder="ssh_username"
              style="width: 300px;"
            ></Input>
          </FormItem>
          <FormItem prop="ssh_password" label="SSH密码" :label-width="200">
            <Input
              type="text"
              size="large"
              v-model="switch_update.ssh_password"
              placeholder="ssh_password"
              style="width: 300px;"
            ></Input>
          </FormItem>
          <FormItem prop="tag" label="备注" :label-width="200">
            <Input
              type="text"
              size="large"
              v-model="switch_update.tag"
              placeholder="tag"
              style="width: 300px;"
            ></Input>
          </FormItem>

          <br />
          <br />
          <FormItem>
            <Button
              type="primary"
              @click="updateswitch()"
              style="position: relative; left: 25%;"
            >
              提交
            </Button>
            <!-- <Button
              @click="handleReset()"
              style="position: absolute; left: 45%;"
            >
              重置
            </Button> -->
          </FormItem>
        </Form>
      </Drawer>
    </Row>
    <br />
    <Row :gutter="10">
      <Table
        v-if="switchdata.length > 0"
        border
        ref="selection"
        :columns="columns"
        :data="switchdata"
      ></Table>
    </Row>
    <br />
    <Row :gutter="10">
      <Page
        :total="this.pagesum"
        show-total
        show-sizer
        :current.sync="page"
        @on-change="updatepage"
        @on-page-size-change="updatepagesize"
        style="position: absolute; left: 70%;"
      />
    </Row>
  </div>
</template>

<script>
import {
  get_snmp,
  post_snmp,
  put_snmp,
  delete_snmp,
  export_snmp,
} from '@/api/http'
import BaseDrawer from '@/components/base/baseDrawer'
import Notice from '@/components/baseNotice'
import expandRow from './TableExpand.vue'

export default {
  name: 'Switchinfo',
  data() {
    return {
      switch_id: 0,
      switchsnold: '',
      issearch: false,
      switch_collects: [],
      switch_interface: [],
      focussn: '',
      searchwithsntmp: '',
      searchwithsn: '',
      showinterfacepage: false,
      showcollectpage: false,
      modal_createtask: false,
      modal_createcollect: false,
      modal_deletetask: false,
      modal_updatetask: false,
      gin_url: 'http://127.0.0.1:8888',
      open_row: [],
      If_all: [],
      switch_update: {
        id: 0,
        name: '',
        community: '',
        ssh_username: '',
        ssh_password: '',
        tag: '',
      },
      If_limit: [],
      switch_create: {
        name: '',
        community: '',
        ssh_username: '',
        ssh_password: '',
      },
      collect_create: {
        collect_ip: '',
        netconf_port: '',
        collect_mode: '服务器代理',
        deviceuuid: '',
        order_id: 0,
        switch_collect_id: 0,
      },
      ruleInline: {
        name: [
          {
            required: true,
            message: 'Please fill in the Name',
            trigger: 'blur',
          },
        ],
        community: [
          {
            required: true,
            message: 'Please fill in the Community',
            trigger: 'blur',
          },
        ],
      },
      ruleInline2: {
        name: [
          {
            required: true,
            message: 'Please fill in the Name',
            trigger: 'blur',
          },
        ],
        community: [
          {
            required: true,
            message: 'Please fill in the Community',
            trigger: 'blur',
          },
        ],
      },
      ruleInline3: {
        collect_ip: [
          {
            required: true,
            message: 'Please fill in the collect_ip',
            trigger: 'blur',
          },
        ],
        order_id: [
          {
            type: 'number',
            required: true,
            message: 'Please fill in the Community',
            trigger: 'blur',
          },
        ],
      },

      columns: [
        {
          type: 'expand',
          width: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row,
              },
            })
          },
        },
        {
          title: '交换机名',
          width: 280,
          key: 'name',
          align: 'center',
        },
        {
          title: '交换机SN',
          width: 220,
          key: 'serial_number',
          align: 'center',
          render: (h, params) => {
            return h(
              'a',
              {
                attrs: {
                  href:
                    'https://grafana.painet.work/d/WoElTcn7k/jiao-huan-ji?orgId=1&var-switchId=' +
                    params.row.serial_number,
                },
              },
              params.row.serial_number,
            )
          },
        },
        {
          title: '状态',
          width: 100,
          key: 'state',
          align: 'center',
          render: (h, params) => {
            const row = params.row
            const color =
              row.state == '未监控'
                ? '#636363'
                : row.state == '监控中'
                ? '#04aa2e'
                : row.state == 'SN冲突'
                ? '#c70000'
                : '#ff0000'

            return h(
              'span',
              {
                style: {
                  color: color,
                },
              },
              row.state,
            )
          },
          filters: [
            {
              label: '未监控',
              value: '未监控',
            },
            {
              label: '监控中',
              value: '监控中',
            },
            {
              label: '上报异常',
              value: '上报异常',
            },
            {
              label: '网络异常',
              value: '网络异常',
            },
            {
              label: 'SN冲突',
              value: 'SN冲突',
            },
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            return row.state == value
          },
        },

        {
          title: '连接方式',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.opencollectpage(params.row)
                    },
                  },
                },
                '查看',
              ),
            ])
          },
        },
        {
          title: 'SNMP账户',
          width: 120,
          align: 'center',
          key: 'community',
        },
        {
          title: '备注',
          tooltip: true,
          width: 120,
          align: 'center',
          key: 'tag',
        },
        {
          title: '创建人',
          width: 130,
          align: 'center',
          key: 'creator',
        },

        {
          title: '创建时间',
          width: 180,
          align: 'center',
          key: 'CreatedAt',
        },
        {
          title: '更新时间',
          width: 180,
          align: 'center',
          key: 'UpdatedAt',
        },

        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',

          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.openinterfacelist(params.row)
                    },
                  },
                },
                '端口详情',
              ),
              h(
                'a',
                {
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.openupdatepage(params.row)
                    },
                  },
                },
                '  |  修改',
              ),
              h(
                'a',
                {
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.deleteSwitch(params.row)
                    },
                  },
                },
                '  |  删除',
              ),
            ])
          },
        },
      ],

      interface_columns: [
        {
          title: 'Id',
          width: 100,
          key: 'id',
          align: 'center',
        },
        {
          title: '端口名',
          width: 250,
          key: 'name',
          align: 'center',
        },
        {
          title: '端口状态',
          width: 100,
          key: 'is_up',
          align: 'center',
          render: (h, params) => {
            const row = params.row
            const color = row.is_up === true ? 'success' : '#989594'
            const text = row.is_up === true ? 'UP' : 'DOWN'

            return h(
              'tag',
              {
                props: {
                  color: color,
                },
              },
              text,
            )
          },
        },
        {
          title: '对端MAC',
          width: 150,
          key: 'machine_mac',
          align: 'center',
        },
        {
          title: '对端服务器ID',
          width: 300,
          align: 'center',
          key: 'deviceuuid',
        },
        {
          title: '是否上联',
          width: 120,
          align: 'center',
          slot: 'is_out',
        },
        {
          title: '阈值告警(OUT)(Mb)',
          width: 150,
          align: 'center',
          slot: 'out_limit',
        },
        {
          title: '阈值告警(IN)(Mb)',
          width: 150,
          align: 'center',
          key: 'in_limit',
          slot: 'in_limit',
        },
      ],
      collect_columns: [
        {
          title: 'Id',
          width: 60,
          key: 'id',
          align: 'center',
        },
        {
          title: '采集SN',
          width: 220,
          key: 'serial_number',
          align: 'center',
          render: (h, params) => {
            return h(
              'a',
              {
                attrs: {
                  href:
                    'https://grafana.painet.work/d/WoElTcn7k/jiao-huan-ji?orgId=1&var-switchId=' +
                    params.row.serial_number,
                },
              },
              params.row.serial_number,
            )
          },
        },
        {
          title: '采集模式',
          width: 130,
          key: 'collect_mode',
          align: 'center',
        },
        {
          title: '代理服务器ID',
          width: 300,
          key: 'deviceuuid',
          align: 'center',
        },
        {
          title: '状态',
          width: 140,
          align: 'center',
          render: (h, params) => {
            const row = params.row

            const color =
              row.state == '未检测'
                ? '#636363'
                : row.state == '联通中'
                ? '#04aa2e'
                : '#ff0000'

            return h(
              'span',
              {
                style: {
                  color: color,
                },
              },
              row.state,
            )
          },
        },
        {
          title: '交换机IP',
          width: 150,
          align: 'center',
          key: 'collect_ip',
        },

        {
          title: '优先级',
          width: 80,
          align: 'center',
          key: 'order_id',
        },

        {
          title: 'NetConf端口',
          width: 150,
          align: 'center',
          key: 'netconf_port',
        },

        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h(
              'Button',
              {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.deletecollect(params.row)
                  },
                },
              },
              '删除',
            )
          },
        },
      ],
      switchdata: [],
      pagesize: 10,
      page: 1,
      pagesum: 0,
    }
  },
  methods: {
    searchdata() {
      this.searchwithsn = this.searchwithsntmp
      this.issearch = true
      this.searchswitch()
    },

    exportSwitchInfo() {
      var params = {}
      if (this.searchwithsntmp != '') {
        var searchSN = this.searchwithsntmp.replace(/\s*/g, '')
        params['switch_sn'] = searchSN
      }
      export_snmp('/api/v1/switch/export', params)
        // export_snmp(this.gin_url + '/api/v1/switch/export', params)
        .then((resp) => {
          let blob = new Blob([resp], {
            type: 'application/octet-stream',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = '资产信息库_export.xlsx'
          link.click()
        })
        .catch((e) => {
          return e
        })
    },

    searchswitch() {
      var params = {}

      if (this.issearch == true) {
        if (this.searchwithsn != '') {
          if (this.switchsnold != this.searchwithsn) {
            this.page = 1
          }

          this.searchwithsn = this.searchwithsn.replace(/\s*/g, '')
          params['switch_sn'] = this.searchwithsn
        } else {
          this.issearch = false
          if (this.switchsnold != '') {
            this.page = 1
          }
        }
      }
      params['page'] = this.page
      params['pagesize'] = this.pagesize

      get_snmp('/api/v1/switch', params)
        // get_snmp(this.gin_url + '/api/v1/switch', params)
        .then((resp) => {
          this.switchdata = resp.switch_list
          this.switchsnold = this.searchwithsn
          this.pagesum = resp.pagesum
        })
        .catch((e) => {
          return e
        })
    },
    updatepage(data) {
      this.page = data
      this.searchswitch()
    },
    updatepagesize(data) {
      this.pagesize = data
      this.searchswitch()
    },

    createcollect(data) {
      this.$refs['createcollect'].validate((valid) => {
        if (valid) {
          this.collect_create.switch_collect_id = this.switch_id
          this.collect_create.collect_ip = this.collect_create.collect_ip.replace(
            /\s*/g,
            '',
          )
          this.collect_create.deviceuuid = this.collect_create.deviceuuid.replace(
            /\s*/g,
            '',
          )

          post_snmp('/api/v1/collect', this.collect_create)
            // post_snmp(this.gin_url + '/api/v1/collect', this.collect_create)
            .then((resp) => {
              if (resp.code == 1) {
                Notice.error('采集任务已存在')
              } else if (resp.code == 2) {
                Notice.error('添加失败，后台错误')
              } else {
                Notice.success('添加成功')
                this.$refs['createcollect'].resetFields()
                this.modal_createcollect = false
              }
              this.searchswitch()
            })
            .catch((e) => {
              console.log(e)
            })
        }
      })
    },

    createswitch(data) {
      this.$refs['createswitch'].validate((valid) => {
        if (valid) {
          this.switch_create.name = this.switch_create.name.replace(/\s*/g, '')
          this.switch_create.community = this.switch_create.community.replace(
            /\s*/g,
            '',
          )
          this.switch_create.ssh_username = this.switch_create.ssh_username.replace(
            /\s*/g,
            '',
          )
          this.switch_create.ssh_password = this.switch_create.ssh_password.replace(
            /\s*/g,
            '',
          )
          post_snmp('/api/v1/switch', this.switch_create)
            // post_snmp(this.gin_url + '/api/v1/switch', this.switch_create)
            .then((resp) => {
              if (resp.code == 1) {
                Notice.error('交换机已存在')
              } else if (resp.code == 0) {
                Notice.success('添加成功')
                this.$refs['createswitch'].resetFields()
                this.modal_createtask = false
              } else {
                Notice.error('添加失败，后台错误')
              }
              this.searchswitch()
              // console.log(this.modal_deletetask, aaa)
            }) 
            .catch((e) => {
              console.log(e)
            })
        }
      })
    },
    openupdatepage(data) {
      this.open_row = data
      this.switch_update.id = data.id
      this.switch_update.community = data.community
      this.switch_update.serial_number = data.serial_number
      this.switch_update.name = data.name
      this.switch_update.ssh_username = data.ssh_username
      this.switch_update.ssh_password = data.ssh_password
      this.switch_update.tag = data.tag
      this.modal_updatetask = true
    },
    updateswitch(data) {
      this.$refs['updateform'].validate((valid) => {
        if (valid) {
          this.switch_update.name = this.switch_update.name.replace(/\s*/g, '')
          this.switch_update.tag = this.switch_update.tag.replace(/\s*/g, '')
          this.switch_update.ssh_username = this.switch_update.ssh_username.replace(
            /\s*/g,
            '',
          )
          this.switch_update.ssh_password = this.switch_update.ssh_password.replace(
            /\s*/g,
            '',
          )
          this.switch_update.community = this.switch_update.community.replace(
            /\s*/g,
            '',
          )
          put_snmp('/api/v1/switch', this.switch_update)
            // put_snmp(this.gin_url + '/api/v1/switch', this.switch_update)
            .then((resp) => {
              if (resp.code == 1) {
                Notice.error('交换机已存在')
              } else if (resp.code == 0) {
                Notice.success('切换成功')
                this.$refs['updateform'].resetFields()
                this.modal_updatetask = false
              } else {
                Notice.error('切换失败，后台错误')
              }
              this.searchswitch()
              // console.log(this.modal_deletetask, aaa)
            })
            .catch((e) => {
              console.log(e)
            })
        }
      })
    },
    updateinterface(row) {
      put_snmp('/api/v1/interface', this.switch_interface)
        // put_snmp(this.gin_url + '/api/v1/interface', this.switch_interface)
        .then((resp) => {
          if (resp.code == 1) {
            Notice.error('更新失败，后台错误')
          } else {
            Notice.success('更新成功')
          }
          this.searchswitch()
          // console.log(this.modal_deletetask, aaa)
        })
        .catch((e) => {
          console.log(e)
        })
      this.$refs['updateform'].resetFields()
      this.modal_updatetask = false
    },
    deleteSwitch(row) {
      this.$Modal.confirm({
        title: 'Title',
        content: '<p>确认删除？</p>',
        onOk: () => {

      delete_snmp('/api/v1/switch/' + row.id)
        // delete_snmp(this.gin_url + '/api/v1/switch/' + row.id)
        .then((resp) => {
          if (resp.code == 0) {
            Notice.success('删除成功')
            this.searchswitch()
          } else {
            Notice.error('删除失败，后台错误')
          }
        })
        .catch((e) => {
          return e
        })
        },
        onCancel: () => {
          this.$Message.info('Clicked cancel')
        },
      })

    },

    deletecollect(row) {
      this.$Modal.confirm({
        title: '',
        content: '<p>确认删除？</p>',
        onOk: () => {
      delete_snmp('/api/v1/collect/' + row.id)
        // delete_snmp(this.gin_url + '/api/v1/collect/' + row.id)
        .then((resp) => {
          if (resp.code == 0) {
            Notice.success('删除成功')
            this.searchswitch()
          } else {
            Notice.error('删除失败，后台错误')
          }
        })
        .catch((e) => {
          return e
        })
        },
        onCancel: () => {
          this.$Message.info('Clicked cancel')
        },
      })
    },

    openinterfacelist(row) {
      this.switch_id = row.id
      this.switch_interface = row.interface_configs
      this.showinterfacepage = true
    },
    syncInterfaceUpdateout(data) {},

    opencollectpage(row) {
      this.switch_id = row.id
      this.switch_collects = row.switch_collects
      this.focussn = row.Serial_number
      this.showcollectpage = true
    },
    openmonitorpage(row) {
      // window.open("https://grafana.painet.work" + row.Panel_url);

      this.$router.push({
        path: '/dashboard/grafana',
        query: {
          url: row.Panel_url,
        },
      })
      // this.$router.push('http://localhost:8080/dashboard/grafana')
    },

    closedrawer() {
      this.$refs['updateform'].resetFields()
    },
    handleReset() {
      this.$refs['updateform'].resetFields()
      this.switch_update = {}
    },
    createSwitchReset() {
      this.$refs['createswitch'].resetFields()
      this.switch_create = {}
    },
    createCollectReset() {
      this.$refs['createcollect'].resetFields()
      this.collect_create = {}
    },
    canelcreatecollect(data) {
      if (this.modal_createcollect == false) {
        this.$refs['createcollect'].resetFields()
      }
    },
  },
  created() {
    this.searchswitch()
  },
}
</script>

<style scoped>
.expand-row {
  margin-bottom: 16px;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
</style>
